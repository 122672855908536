
.Divider {
    font-weight: bold;
    font-size: 25px;
    text-align: center;
}

h3.LinkHeader {
    text-decoration: underline;
    cursor: pointer;
}

.ProjectShort {

}

.ProjectMedium {
    
}