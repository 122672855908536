.MemoryPage {
    background: white;
    width: 100%;
    padding: 84px 97px 129px;
    font-size: 30px;
    line-height: 1.26;
    font-weight: 400;
    color: #666666;

    h1 {
        font-size: 50px;
        line-height: 1.14;
        font-weight: 700;
        color: #60A8F0;
        font-variant: small-caps;
        text-transform: uppercase;
    }
    header {
        color: #C8C8C8;
        font-size: 30px;
    }
    h2 {
        margin: 0;
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
    }
    section {
        margin-top: 30px;
    }
    section p:first-of-type {
        margin-top: 0;
    }
    section[data-grid="2"] {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 30px;
        margin-top: 0;
        @media screen and (max-width: 560px) {
            grid-template-columns: 1fr;
        }
    }
    .StatusIcon {
        width: 64px;
        height: 64px;
    }
    @media screen and (max-width: 1200px) {
        padding: 40px 48px 76px;
    }
    @media screen and (max-width: 820px) {
        font-size: 22px;
        h1 {
            font-size: 44px;
        }
        h2 {
            font-size: 26px;
        }
        header {
            font-size: 26px;
        }
    }
    @media screen and (max-width: 560px) {
        padding: 26px 28px 40px;
        font-size: 20px;
        h1 {
            font-size: 34px;
        }
        h2 {
            font-size: 22px;
        }
        header {
            font-size: 22px;
        }
    }

}
