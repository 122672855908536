@import "../../styles/variables";

.Registry {
    background: white;
    padding: 66px 69px;
    // padding: clamp(10px, 6vw, 66px) clamp(10px, 6vw, 69px);
    // padding: 4.125em 4.3125em;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media screen and (max-width: 560px) {
        gap: 4px;
    }
}

.Registry__Filter {
    font-size: 16px;
    max-width: 1367px;
    display: flex;
    gap: 1em 2em;
    margin-bottom: 2em;
    flex-wrap: wrap;

    & > *:nth-child(1) {
        min-width: 10em;
    }
    & > *:nth-child(2) {
        min-width: 18em;
    }
}

@media screen and (max-width: 820px) {
    .Registry {
        padding: 33px 35px;
    }
}

@media screen and (max-width: 560px) {
    .Registry {
        padding: 22px 25px;
    }
    .Registry__Filter {
        margin-bottom: 1.5em;
    }
}

