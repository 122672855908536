@import "../../styles/variables";

.Layout {  

  &__Content {
    display: flex;
    padding: 0 20px;
    gap: 20px;

    @media (max-width: $media_sm) {
      padding: 0 15px;
    }
  }

  &__ContentSingle {
    padding: 0 20px;

    @media (max-width: $media_sm) {
      padding: 0 15px;
    }
  }


  &__Right {
    -ms-flex: 0 0 calc(70% - 20px);
    flex: 0 0 calc(70% - 20px);
    max-width: calc(70% - 20px);

    @media (max-width: $media_md) {
      -ms-flex: 0 0 calc(60% - 20px);
      flex: 0 0 calc(60% - 20px);
      max-width: calc(60% - 20px);
    }

    @media (max-width: $media_sm) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__ContentPage {
    background: #fff;
    color: #666666;
    padding: 36px 36px 72px;
    font-size: 16px;
    line-height: 1.6;

    @media (max-width: $media_sm) {
      padding: 18px 18px 36px;
      line-height: 1.4;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    h1 {
      color: #60A8F0;
      margin-top: 14px;
      margin-bottom: 38px;
      font-size: 25px;
      line-height: 1.145;
    }

    h3 {
      color: #666666;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1.3;
      margin-top: 54px;
      margin-bottom: 10px;
    }
    h1 + h3 {
      margin-top: 38px;
    }
    ul  {
      margin: 0;
      padding: 0;
      padding-left: 2em;
    }
    li {
      margin: 1em 0;
    }
    li p {
      margin: 0; padding: 0;
    }
    li p + li p {
      margin-top: 1em;
    }
    figure {
      margin: 2em 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
      max-width: 1200px;
      img {
        max-width: 100%;
      }
    }
    figure[nowrap] {
      gap: 10px;
      justify-content: flex-start;
      @media screen and (min-width: 1020px) {
        flex-wrap: nowrap;
        img {
          max-width: calc(50% - 10px);
        }
        iframe {
          max-width: calc(50% - 10px);
        }
      }
    }
  }
}