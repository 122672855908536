ul.gallery {
    --columns: 2;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: 20px 10px;
}
ul.gallery br {
    display: none;
}
ul.gallery li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul.gallery figure {
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
ul.gallery figcaption {
    display: block;
}
ul.gallery img {
    object-fit: cover;
    width: 100%;
    max-height: none;
}

@media screen and (max-width: 1200px) {
    ul.gallery {
        --columns: 1;
    }
}
