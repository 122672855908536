.Roadmap {
    background: white;
    color: #525252;
    padding: 22px 22px 90px;
    font-weight: 400;

    h1 {
        margin-top: 97px;
        margin-bottom: 87px;
        margin-left: calc(166px + 47px + 2px);
        font-size: 50px;
        font-weight: 700;
        color: #60A8F0;
        text-transform: uppercase;
    }

    section {
        display: flex;

        .date {
            width: 166px;
            flex-shrink: 0;
            font-size: 17px;
            color: #60A8F0;
            text-align: right;
            font-weight: 700;
            header {
                font-size: 25px;
                font-weight: 700;
            }
        }
        .content {
            margin-left: 47px;
            padding-left: 47px;
            border-left: 4px solid #60A8F0;
            position: relative;
            font-size: 25px;
            line-height: 1.2;
            padding-bottom: 64px;
            max-width: 1024px;

            .timelineDot {
                width: 26px;
                height: 26px;
                background: #60A8F0;
                border-radius: 50%;
                position: absolute;
                left: -15px;
            }

            h2 {
                margin-top: 0;
                font-size: 30px;
                font-weight: 700;
                color: black;
            }
            h3 {
                margin-top: 50px;
                font-size: 30px;
                font-weight: 700;
                color: black;
            }
            .statusLabel {
                font-size: 25px;
                color: #E72951;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        &.lastSection {
            .content {
                padding-bottom: 24px;
            }
        }
        &:last-child {
            .content {
                padding-bottom: 0;
            }
        }
        .mobileDate {
            display: none;
        }
    }

    @media screen and (max-width: 1300px) {
        h1 {
            margin-left: 30px;
            font-size: 37px;
            margin-top: 72px;
            margin-bottom: 65px;
        }
        section {
            .date {
                width: 128px;
                font-size: 13px;
                header {
                    font-size: 18px;
                }
            }
            .content {
                font-size: 18px;
                padding-bottom: 48px;
                margin-left: 35px;
                padding-left: 35px;

                h2, h3 {
                    font-size: 22px;
                }
                h3 {
                    margin-top: 32px;
                }

                .statusLabel {
                    font-size: 18px;
                    color: #E72951;
                    font-weight: 500;
                    text-transform: uppercase;
                }

            }
        }
    }
    @media screen and (max-width: 600px) {
        h1 {
            margin-left: 20px;
            font-size: 37px;
            margin-top: 50px;
            margin-bottom: 50px;
        }
        section {
            .date {
                display: none
            };
            .content {
                margin-left: 15px;

                .mobileDate {
                    display: block;
                    font-size: 13px;
                    font-weight: 700;
                    color: #60A8F0;
                    header {
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
                h2, h3 {
                    margin-top: 20px;
                }
            }
        }
    }
}
