@import "../../styles/variables";

.Menu {
  position: fixed;
  right: 0;
  top: 0;
  max-width: 100%;
  background: #fff;
  min-height: 100%;
  padding: 70px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  transform: translateX(100%);
  transition: all .3s ease-out;

  @media (max-width: $media_sm) {
    padding: 70px 20px 20px 20px;
  }

  &__Open {
    transform: translateX(0);
    transition: all .3s ease-out;
  }

  &__Item {
    text-decoration: none;
    color: #000;
    padding: 10px 0;
    font-weight: 500;
    font-size: 15px;

    &:hover, &_Active {
      color: var(--accent-color);
    }

    &_Disabled {
      color: #ccc !important;
    }
  }
}

.Blur {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  visibility: hidden;
  opacity: 0;
  transition: all .3s;

  &__Open {
    visibility: visible;
    opacity: 1;
    transition: all .3s;
  }
}

.Close {
  position: absolute !important;
  width: 40px;
  height: 40px;
  right: 15px;
  top: 15px;
}