.SignHistoryItem {
    figure {
        margin: 0;
        padding: 0;
    }
}

.date {
    font-size: 20px;
}

.statusIcon {
    width: 42px;
}