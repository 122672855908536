.MemoryForm {

}

.Input {
  margin-bottom: 20px;
  width: 100%;
}

.Map {
  width: 100%;
  height: 300px;
}

.Title {
  margin-top: 0;
}

.Alert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .SuccessInput {
    fill: green;
    height: 70px;
    width: 70px;
  }

  .ErrorInput {
    fill: red;
    height: 70px;
    width: 70px;
  }
}
