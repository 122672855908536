@import "../../styles/variables";

.Footer {
  &__Wrap {
    padding: 25px 20px;
    display: flex;
    gap: 20px;
    color: #fff;
    flex-wrap: wrap;

    @media (max-width: $media_sm) {
      flex-direction: column;
    }
  }

  &__Left {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;

    @media (max-width: $media_md) {
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      max-width: 40%;
    }

    @media (max-width: $media_sm) {
      order: 2;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__Apps {
    display: flex;
    gap: 25px;
  }

  &__AppsItem {
    display: flex;
    gap: 10px;
    flex: 1;
    justify-content: flex-end;

    @media (max-width: $media_lg) {
      flex-direction: column;
    }
  }

  &__AppImage {
    height: auto;
    align-self: flex-start;

    @media (max-width: $media_lg) {
      width: 100%;
    }
  }

  &__Qr {
    width: 100%;
    max-width: 408px;

    @media (min-width: 800px) {
      width: 100%;
      // margin-top: -36px;
    }
  }

  &__Right {
    display: flex;
    flex-direction: column;
    flex: 1;
    // flex-wrap: wrap;

    @media (max-width: $media_sm) {
      flex-direction: column;
    }
  }

  &__Desc {
    // -ms-flex: 0 0 42%;
    // flex: 0 0 42%;
    // max-width: 42%;
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    margin-top: auto;

    @media (max-width: $media_md) {
      font-size: 18px;
    }
    @media (max-width: $media_sm) {
      font-size: 16px;
      // order: 2;
      // -ms-flex: 0 0 100%;
      // flex: 0 0 100%;
      max-width: 100%;
      font-size: 17px;
      text-align: center;
    }
  }

  &__MarkersGrid {
    font-weight: 500;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(5, max-content);
    justify-items: center;
    align-items: center;
    gap: 10px;
    font-size: 22px;

    @media (min-width: $media_md) {
      div:nth-child(5n-4) {
        justify-self: start;
        padding-right: 5px;
      }
    }
    @media (max-width: $media_md) {
      font-size: 15px;
      @media (max-width: $media_sm) {
        font-size: 12px;
      }

      // grid-template-columns: repeat(4, max-content);
      grid-template-columns: auto;
      grid-template-rows: repeat(5, min-content);
      grid-auto-flow: column;
      div:nth-child(2), div:nth-child(3), div:nth-child(4), div:nth-child(5) {
        justify-self: start;
      }
      div:nth-child(5n-4) {
        justify-self: start;
        padding-right: 5px;
        text-align: center;
      }
    }

    margin-bottom: 40px;
  }

  &__Markers {
    margin-left: auto;

    @media (max-width: $media_sm) {
      margin-left: 0;
      display: flex;
      justify-content: center;
      margin-bottom: 11px;
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  &__MarkerItem {
    display: flex;
    align-items: center;
    margin-top: -10px;
    gap: 13px;
    font-weight: 500;
    font-size: 1.2vw;

    &:first-of-type {
      margin-top: 0;
    }

    @media (max-width: $media_sm) {
      margin-top: 0;
      gap: 3px;
      font-weight: 300;
      white-space: nowrap;
      font-size: 11px;
    }
  }
  &__MarkerItemDivider {
    height: 20px;
  }

  &__Group {
    height: 43px;
    max-width: 38px;

    @media (max-width: $media_sm) {
      height: 25px;
    }
  }
  &__GroupSmall {
    // height: 34px;
    // max-width: 30px;

    // @media (max-width: $media_sm) {
    //   height: 20px;
    // }
    height: 43px;
    max-width: 38px;
    transform: scale(0.7);

    @media (max-width: $media_sm) {
      height: 25px;
    }
  }

  &__Copy {
    background: #fff;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    padding: 20px;
    margin-top: 50px;

    a {
      color: #000;
    }

    @media (max-width: $media_md) {
      font-size: 20px;
    }
  }
}

.Footer.FooterSmall .Footer__Copy {
  margin-top: 21px;
}