.Search {
  background: #fff;
  padding: 10px;
  border-radius: 8px;

  &__Head {
    display: flex;
  }

  &__Input {
    flex: 1;
    outline: 0;
    font-family: 'GT Walsheim Pro', sans-serif;
  }

  &__Button {
    border: 0;
    background: var(--accent-color);
    color: #fff;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 15px;
  }
}