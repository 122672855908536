@import "../../styles/variables";

.MemoryInfo {
  background: #fff;
  flex: 1;
  border-radius: 8px;
  padding: 20px 10px 48px 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-height: 380px;

  @media (max-width: $media_sm) {
    min-height: calc(80vh - 210px);
    max-height: calc(100vh - 210px);
  }

  &__Full {
    min-height: auto;
    max-height: none;
    margin-top: 20px;
  }

  &__Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    margin-top: 0;

    @media (max-width: $media_sm) {
      margin-bottom: 10px;
    }
  }

  &__Wrap {
    display: flex;
    gap: 10px;
    overflow: hidden;
    max-height: 235px;

    @media (max-width: $media_lg) {
      max-height: 207px;
    }

    @media (max-width: $media_sm) {
      max-height: none;
      flex-direction: column;
      overflow: auto;
    }
  }

  &__Left {
    width: 60%;
    max-width: 60%;
    overflow: auto;

    @media (max-width: $media_sm) {
      order: 2;
      width: 100%;
      max-width: 100%;
      overflow: initial;
    }
  }

  &__Right {
    width: 40%;
    max-width: 40%;

    @media (max-width: $media_sm) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__Desc {
    margin-top: 0;
  }

  &__Address {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    width: 100%;
    padding: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    border-top: 1px solid var(--border-color);
  }

  &__Image {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__Default {
    width: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    opacity: 0.4;
  }
}