@import "../../styles/variables";

.Home {
  padding: 0 20px;
  display: flex;
  gap: 20px;

  @media (max-width: $media_sm) {
    padding: 0 15px;
    flex-direction: column;
  }

  &__Left {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: $media_md) {
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      max-width: 40%;
    }

    @media (max-width: $media_sm) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      gap: 10px;
    }
  }


  &__ButtonGroup {
    display: flex;
    align-items: stretch;
    gap: 25px;
  }

  &__Button {
    flex: 1;
    background: var(--accent-color);
    color: #fff;
    border: 5px solid #FFFFFF;
    border-radius: 15px;
    padding: 20px 20px;
    font-weight: 800;
    font-size: 1.2vw;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: $media_sm) {
      font-size: 16px;
      text-transform: uppercase;
      padding: 10px 20px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

.ModalContent {
  position: relative;
  background: #fff;
  padding: 30px;
  width: 600px;
  max-width: 100%;
  border-radius: 10px;
  max-height: 90%;
  margin: 30px 0;
  overflow: auto;

  &__About {
    width: 1200px;
  }
}

.Close {
  fill: black;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}