@import "../../styles/variables";

.Header {
  padding: 20px 20px 20px calc(30% + 27px);
  color: #fff;

  @media (max-width: $media_md) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Left {
    display: flex;
    align-items: flex-end;
    margin-left: -210px;
    position: relative;
    color: #fff;
    text-decoration: none;

    @media (max-width: $media_md) {
      margin-left: 0;
    }
  }

  &__Logo {
    height: 70px;

    @media (max-width: $media_sm) {
      height: 35px;
    }
  }

  &__LogoMini {
    height: 30px;
    transform: translateY(2px);
  }

  &__Desc {
    font-weight: 400;
    font-size: 29px;
    margin-left: 20px;
    transform: translateY(-6px);

    @media (max-width: $media_sm) {
      position: absolute;
      left: 31px;
      bottom: -8px;
      font-size: 10px;
      white-space: nowrap;
      margin: 0;
      transform: translate(0);
    }
  }

  &__Buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__Button {
    background: transparent;
    padding: 0;
    border: 0;
    outline: none;
    height: 35px;
    cursor: pointer;
  }

  &__ButtonIcon {
    height: 35px;
  }

  &__More {
    background: transparent;
    border: 5px solid #FFFFFF;
    border-radius: 15px;
    width: 100%;
    display: block;
    height: 55px;
    font-weight: 800;
    font-size: 25px;
    line-height: 29px;
    color: #fff;
    margin-top: 21px;
  }
}