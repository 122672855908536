@import "fonts";

* {
  box-sizing: border-box;
}

:root {
  --accent-color: #e72951;
  --border-color: #ced4da;
}

body {
  margin: 0;
  font-family: "GT Walsheim Pro", sans-serif;
  background: var(--accent-color);
}

.ymaps-2-1-79-copyright {
  display: none !important;
}

img {
  max-width: 100%;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
