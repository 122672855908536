@import "../../styles/variables";

.MapApp {
  height: 600px;

  @media (max-width: $media_sm) {
    height: calc(70vh);
  }

  &__Selected {
    height: calc(50vh);
  }
}

.MapAppContainer {
  @media (max-width: $media_sm) {
    margin: 0 -15px;
  }
}