.RegistryNode {
    max-width: 1367px;

    &__Title {
        cursor: pointer;
        user-select: none;
    }

    &__Items {
        display: none;
        flex-direction: column;
        gap: 8px;
        @media screen and (max-width: 560px) {
            gap: 6px;
        }
        &__Open {
            display: flex;
        }
    }
}

.RegistryNode[data-level="1"] {
    background: #D9D9D9;
    .RegistryNode__Title {
        font-size: 30px;
        line-height: 1.1333;
        padding: 15px 76px;
        color: black;
        font-weight: 500;
    }
    .RegistryNode__Items {
        padding: 8px 76px 25px;
    }
    @media screen and (max-width: 820px) {
        .RegistryNode__Title {
            padding: 15px 56px;
        }
        .RegistryNode__Items {
            padding: 8px 56px 20px;
        }
    }
    @media screen and (max-width: 560px) {
        margin: 0px -25px;
        .RegistryNode__Title {
            font-size: 26px;
            line-height: 1.2;
            padding: 8px 30px;
        }
        .RegistryNode__Items {
            padding: 4px 30px 13px;
        }
    }
}

.RegistryNode[data-level="2"] {
    background: #EDEDED;
    .RegistryNode__Title {
        font-size: 25px;
        line-height: 1.12;
        padding: 12px 31px;
        font-weight: 500;
        color: #545454;
    }
    .RegistryNode__Items {
        padding: 8px 31px 28px;
    }
    @media screen and (max-width: 820px) {
        .RegistryNode__Title {
            padding: 12px 28px;
        }
        .RegistryNode__Items {
            padding: 8px 28px 28px;
        }
    }
    @media screen and (max-width: 560px) {
        .RegistryNode__Title {
            font-size: 22px;
            line-height: 1.2;
            padding: 6px 18px;
        }
        .RegistryNode__Items {
            padding: 4px 18px 14px;
        }
    }
}

.RegistryNode__Card {
    display: flow-root;
    background: #F5F5F5;
    color: #545454;
    font-size: 20px;
    line-height: 1.15;
    padding: 16px;
    font-weight: 500;
    text-decoration: none;

    & img {
        max-height: 118px;
        max-width: 90px;
        float: left;
        margin-right: 20px;
    }
    @media screen and (max-width: 560px) {
        font-size: 18px;
        padding: 8px;
        & img {
            max-width: 100%;
            display: block;
            margin-right: 0px;
            margin-bottom: 10px;
            display: block;
            float: none;
        }
    }
    header {
        // text-decoration: underline;
    }
    article {
        margin-top: 0.5em;
        background: hsla(0, 50%, 100%, 0.5);
        width: max-content;
        display: flex;
        padding: 0.5em 0.8em;
        border-radius: 8px;
        align-items: center;

        img {
            max-height: 36px;
        }
    }
}
